import React, { useEffect, useState } from "react";
import BottomNavbar from "../components/BottomNavbar";
import Header from "../components/Header";
import SearchBox from "../components/SearchBox";
import Heading from "../components/Heading";
import Layout from "../components/Layout";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import Loader from "../components/Loader";

import {
  Box,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Badge,
  Avatar,
  ListItemText,
} from "@mui/material";
import classes from "./Leaderboard.module.css";
import axios from "axios";
import { baseURL, leaderBoardURL } from "../api";
import { toast } from "react-toastify";

const LeaderBoard = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    const fetchDataFromBackend = async () => {
      try {
        setLoading(true);
        const res = await axios.post(`${baseURL}${leaderBoardURL}`);
        setData(res.data.result);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error(
          err?.data?.message ||
            err?.message ||
            err?.response ||
            err?.response?.message ||
            err?.response?.data?.message
        );
      }
    };
    fetchDataFromBackend();
  }, []);
  return (
    <Layout>
      <Header />
      <SearchBox />
      <Heading
        title="Leaderboard"
        icon={<LeaderboardIcon sx={{ position: "relative", top: "11%" }} />}
      />
      <Box
        mb={4}
        mt={-2}
        sx={{ backgroundColor: "#e9f1fa", borderRadius: "20px" }}
      >
        <Box sx={{ margin: "0 0", padding: "2rem 0" }}>
          <List>
            {loading ? (
              <Loader />
            ) : (
              <>
                {data?.map((dataItem, i) => {
                  return (
                    <React.Fragment key={i}>
                      <ListItem
                        secondaryAction={
                          <Box
                            className={classes.points_box}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            {/* <MilitaryTechIcon color="primary" /> */}
                            <span className={classes.coin}></span>
                            <Typography variant="body1">
                              <span style={{ fontWeight: "bold" }}>
                                {dataItem.points}
                              </span>
                            </Typography>
                          </Box>
                        }
                      >
                        <ListItemAvatar>
                          <Badge
                            badgeContent={i + 1}
                            color="success"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                          >
                            <Avatar src="/images/broken-image.jpg" />
                          </Badge>
                        </ListItemAvatar>
                        <ListItemText
                          primary={dataItem.ani}
                          secondary={
                            <>
                              <Typography
                                //   sx={{ width: "50%", display: "flex", flexWrap: "wrap" }}
                                variant="body1"
                              >
                                Score:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {dataItem.points}
                                </span>
                                {/* <AccessTimeIcon
                                  sx={{
                                    marginBottom: "-0.3rem",
                                    fontSize: "1.2rem",
                                  }}
                                /> */}
                                {/* <span style={{ fontWeight: "bold" }}>
                                  {" "}
                                  188.3
                                </span>{" "} */}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </List>
        </Box>
      </Box>

      <BottomNavbar value={2} />
    </Layout>
  );
};

export default LeaderBoard;
