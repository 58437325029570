import React from "react";
import { useNavigate, useRouteError } from "react-router-dom";
import classes from "./ErrorBoundary.module.css";
import SearchBox from "./SearchBox";
import Layout from "./Layout";
import Header from "./Header";
import { Box, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ErrorBoundary = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  const handleNavigate = (route) => {
    if (localStorage.getItem("serviceId") == 8) {
      navigate(`${route}?serviceId=8`);
    } else {
      navigate(route);
    }
  };
  return (
    <Layout>
      <Header />
      <SearchBox />
      <Box sx={{ backgroundColor: "#e9f1fa", borderRadius: "20px" }}>
        <Box sx={{ margin: "0 2rem", padding: "2rem 0" }}>
          <div className={classes.message_container}>
            <p className={classes.text}>Some Error Occured!</p>
            <p className={classes.text}>{error?.message || "Unknown Error"}</p>
            <Button
              variant="contained"
              color="error"
              startIcon={<ArrowBackIcon />}
              onClick={() => handleNavigate("/")}
            >
              Go Back
            </Button>
          </div>
        </Box>
      </Box>
    </Layout>
  );
};

export default ErrorBoundary;
