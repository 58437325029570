import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { Box, Typography, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import classes from "./GameResults.module.css";
import BottomNavbar2 from "../components/BottomNavbar2";
import { useParams } from "react-router-dom";
import AccordianComponent from "../components/AccordianComponent";
import HeaderGameResults from "../components/HeaderGameResults";
//  Will add some logic of localStorage here to prevent hitting the page
// refresh and call the api

const GameResults = () => {
  const { category, categoryId } = useParams();

  const {
    correctAnswer,
    wrongAnswer,
    correctQuestions,
    wrongQuestions,
    answerChoosed,
  } = useSelector((state) => state.answers);

  useEffect(() => {
    window.history.pushState(null, null, document.URL);
    window.addEventListener("popstate", function () {
      window.history.pushState(null, null, document.URL);
    });
  }, [window]);

  const { score } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.user);
  const { opponent, opponentScore } = useSelector((state) => state.opponent);

  return (
    <Layout>
      {/* <Header2 title="Quiz Results" /> */}
      <HeaderGameResults title="Quiz Results" />
     
      <Box>
        <Typography
          variant="h4"
          sx={{ color: "white", textAlign: "center", fontWeight: "bold" }}
        >
          {score > opponentScore
            ? "You Won!"
            : score < opponentScore
            ? "You Lose!"
            : "Draw!"}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          // paddingLeft: "4rem",
          // marginLeft: "2rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ borderRadius: "50%", width: "25%" }}>
          <img
            style={{
              borderRadius: "50%",
              width: "100%",
              height: "100%",
              objectFit: "contain",
              objectPosition: "center center",
            }}
            src="/images/Avatar.jpg"
            // src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIMAAACDCAMAAACZQ1hUAAAAY1BMVEUiLTqzusC2vcO5wMa8w8keKjccKDYYJDMRIC8VIjEZJjQgKzkNHS2wt72Ij5YAEiWRmJ8HGSpfZ3Ckq7J2fYWBiJCepaxJUVsxO0Y3QEuqsbdCS1ZYYGltdX08RVAqNEFQWWLPmQz1AAAEEklEQVR4nO2a23KjMAyGAWNjczDmDAFC3v8pFxLaJt2sYxGLdmb5r9orvpFkSZHkOIcOHTp06NChQ4f+S9GAcyEE5wH9ke8zEcfnvKyklFWZn7NY7M0hsr6sie8Tb9HyR12es2BPgiiv50+795r/r3O2FwVlw4k8AqwYpBj2iQze1v4TgJVCTQIfIc69Zzb4pHCHGJmABiXRECwiI7IlYvkKYYYoM0yE1ABhhqhSPAQx/jMaH+TnaO4IGl003svrQyyIwpihRmLIKpNguIlUKHHJOmOCRS1GwhTS1BNXb1QIYUknc09cIRz7huAvE+SjyMitMzg1xBXL02C2CegZRDArOdt2hmmK/JKf225oMtCrWESk7SKeFkAE1y1sV64oATOcLPsiOsMZks5uULIejOC6/S9gaOxmCNZsYbBrB/oL7EA7eEy6thMlO4ERkimyy7AhR51st1KZguZqT9nOk9D2YWkpbdcLdgHXrIvt3pq20KA82e9qQ2BAIPRRjshhTQxKPxkB7eBYzg6LOOz3hcT42ct6EIPlYrEqBUSl/QR1E4XUbxwzmA2CbrLfU38omkwLeDKhjSnDi+Es6II4rzUbgxCJOpgLa4PZIEKWvheNXkKQGmHy8AjxajDmFROuGa4QWkuQHRBmiFD++3X4ku2zzokvyXNTkAR9aP8p0Ur376jwXNXusLz4EI0bRR72GJ5HVMN3CIV7irQta/dzp+bW5ZTuv2CkIpiasZJKVmMzhQKhbTLCYPwqwX4I4NChX6coYmEYLApDFu2eHJjgoTN1zWXIFw2XppuccH6j+3yesjjNzmOliuR2dEBuJwhJoeZcxdMYu3BSkU1DVRCfPFl6X5EKObQp4jlGEHajSr4fPnwH8ZI67wKUxpoKNqjEN/m55/mJykPrxqBZW5oBfGJUndVCyuJeaT3w3Cuqj609FN7JBEpwpUhkZ2cYE2Yj2AYfIm7JLURnNpygk8kHiiTP3gwLFsnNRrhpDgvnrUGlOBfvGGE1RdG/ERXp9kh4NMW4dTJEY8DBg15ExpuCgjrGox8TCGdLqhDKHsIMoeBXbFFmMO0AQdRgdwiLjlghFDBb8RK65jeAqEBPNM5tW+EKkQMmAwx8cGEmrzd/HNz4GAvIUBh7AyMYbiKlIQRsSQCEaMzqFwdeAEHk1UbTonDAQ5ghjC51UqSAXBlOBiU0REkNXyIGhggQo2GRwWkl67He5Yf8l2sm833RVnmv9kzwtTZcrxbhuA/zJm/QR2WG7orFGfpVE8d3hesm2qKx6RQLLm0JD3L8cJidMepSBK92YZA6Z2TISXJlKHRBmW64BtsiHUO8hxlmQ2hWDdTBzw6LfM11J22xC9bKoLnmo91ODJoEgV+4VwbNCdnB8KMMfwBRxT2y5SYoeAAAAABJRU5ErkJggg=="

            // src="https://demo.quizpro.mobi//uploads/user/2/avatar.png"
            alt="You"
          />
        </Box>
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Typography variant="h6" sx={{ color: "white" }}>
          {score > opponentScore
            ? "Congratulations"
            : score < opponentScore
            ? "Try Again!"
            : "It was a tough call"}
        </Typography>
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{
            backgroundColor: "#e9f1fa",
            borderRadius: "20px",
            padding: "1rem 2rem",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <span className={classes.coin}></span>
            {user?.points}
          </Typography>
        </Box>
      </Box>
      <Box mt={2} className={classes.score_box}>
        <Box className={classes.final_score_box}>
          <Typography variant="h6">Final Score</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            marginTop: "10%",
            gap: "10%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Grid container spacing={6}>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "1rem 3rem",
                    borderRadius: "12px",
                    backgroundColor: "#A09489",
                    color: "#fff",
                  }}
                >
                  <Typography sx={{ textAlign: "center" }} variant="h6">
                    {score}
                  </Typography>
                </Box>
                <Typography sx={{ textAlign: "center" }} variant="h6">
                  You
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "12px",
                    padding: "1rem 3rem",
                    backgroundColor: "#A09489",
                    color: "#fff",
                  }}
                >
                  <Typography sx={{ textAlign: "center" }} variant="h6">
                    {opponentScore}
                  </Typography>
                </Box>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  {opponent.name}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box mb={2}>
        <AccordianComponent
          correctAnswer={correctAnswer}
          wrongAnswer={wrongAnswer}
          correctQuestions={correctQuestions}
          wrongQuestions={wrongQuestions}
          answerChoosed={answerChoosed}
        />
      </Box>
      <BottomNavbar2 category={category} categoryId={categoryId} />
    </Layout>
  );
};

export default GameResults;
