import React from "react";
import {
  Box,
  Paper,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import GridViewIcon from "@mui/icons-material/GridView";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const BottomNavbar = (props) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  // console.log(user);
  const handleNavigate = (route) => {
    navigate(route);
  };
  const checkTokenAndNavigate = () => {
    if (!user) {
      if (localStorage.getItem("serviceId") == 8) {
        navigate("/login?serviceId=8");
      } else {
        navigate("/login");
      }
    } else {
      navigate("/account");
    }
  };
  return (
    <Box sx={{ backgroundColor: "#e9f1fa", borderRadius: "20px" }}>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: {
            xs: "5%",
            sm: "5%",
            md: "5%",
            lg: "32%",
            xl: "32%",
          },
          right: {
            xs: "5%",
            sm: "5%",
            md: "5%",
            lg: "32%",
            xl: "32%",
          },
          borderRadius: "24px 24px 0 0",
        }}
        elevation={3}
      >
        <BottomNavigation
          value={props.value}
          //   onChange={(event, newValue) => {
          //     setValue(newValue);
          //   }}
          sx={{
            backgroundColor: "#fff",
            borderRadius: "24px 24px 0 0",
            boxShadow: "0 -5px 5px rgba(12,9,42,0.1)",
          }}
        >
          <BottomNavigationAction
            onClick={() => handleNavigate("/")}
            label="Home"
            icon={<HomeIcon />}
          />
          <BottomNavigationAction
            onClick={() => handleNavigate("/all-quizes")}
            label="All Quizes"
            icon={<GridViewIcon />}
          />
          <BottomNavigationAction
            onClick={() => handleNavigate("/leaderboard")}
            label="Leader Board"
            icon={<LeaderboardIcon />}
          />
          <BottomNavigationAction
            onClick={() => {
              checkTokenAndNavigate();
            }}
            label={props.label ? props.label : "Account"}
            icon={<PersonIcon />}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

export default BottomNavbar;
