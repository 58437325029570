import React from "react";
import { Box, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";

const HeaderGameResults = (props) => {
  const { title } = props;
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    if (localStorage.getItem("serviceId") == 8) {
      navigate(`${route}?serviceId=8`);
    } else {
      navigate(route);
    }
  };
  return (
    <Box
      mt={4}
      ml={0}
      mr={0}
      sx={{
        position: "relative",
        textAlign: "center",
        color: "white",
        fontWeight: "bold",
      }}
    >
      <KeyboardBackspaceIcon
        onClick={() => handleNavigate("/")}
        sx={{
          color: "#fff",
          display: "inline-block",
          position: "absolute",
          marginRight: "0.5rem",
          left: "0.75rem",
          cursor: "pointer",
          fontSize: "2rem",
        }}
      />
      <Typography variant="h6" sx={{ marginTop: "0.5%" }}>
        {title}
      </Typography>
    </Box>
  );
};

export default HeaderGameResults;
