import React from "react";
import { useNavigate } from "react-router-dom";
import classes from "./PageNotFound.module.css";
import SearchBox from "../components/SearchBox";
import Layout from "../components/Layout";
import Header from "../components/Header";
import { Box, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const PageNotFound = () => {
  const navigate = useNavigate();

  const navigateHandler = () => {
    if (localStorage.getItem("serviceId") == 8) {
      navigate("/?serviceId=8");
    } else {
      navigate("/");
    }
  };

  return (
    <Layout>
      <Header />
      <SearchBox />
      <Box sx={{ backgroundColor: "#e9f1fa", borderRadius: "20px" }}>
        <Box sx={{ margin: "0 2rem", padding: "2rem 0" }}>
          <div className={classes.message_container}>
            <p className={classes.text}>Page Not Found!</p>
            <Button
              variant="contained"
              color="error"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigateHandler()}
            >
              Go Back
            </Button>
          </div>
        </Box>
      </Box>
    </Layout>
  );
};

export default PageNotFound;
