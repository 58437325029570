import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Header from "../components/Header";
import Guest from "../components/Guest";
// import PopularQuiz from "../components/PopularQuiz";
import PopularQuiz2 from "../components/PopularQuiz2";
import FeaturedQuiz from "../components/FeaturedQuiz";
// import Carousel from "../components/Carousel";
import BottomNavbar from "../components/BottomNavbar";
import SearchBox from "../components/SearchBox";
import axios from "axios";
import { baseURL, quizURL } from "../api";
import { toast } from "react-toastify";

const Home = () => {
  const [quizes, setQuizes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const service_id = params.get("serviceId");

    
    const fetchDataFromBackend = async () => {
      try {
        setLoading(true);
        const res = await axios.post(`${baseURL}${quizURL}`);
        // console.log(res);
        setQuizes(res.data.result);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        toast.error(err?.response?.data?.message || err?.message);
        // console.log(err);
      }
    };
    fetchDataFromBackend();
    
    if (!localStorage.getItem("serviceId")) {
      if (!service_id || service_id.trim().length == 0 || service_id == "") {
        return;
      }
      localStorage.setItem("serviceId", service_id);
    }

  }, [window.location.search, localStorage]);
  return (
    <Layout>
      <>
        <Header />
        <SearchBox />
        <Guest />
        {/* <PopularQuiz /> */}
        <PopularQuiz2 quizes={quizes} loading={loading} />
        <FeaturedQuiz quizes={quizes} loading={loading} />
        {/* <Carousel /> */}
        <BottomNavbar value={0} />
      </>
    </Layout>
  );
};

export default Home;
