import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Header2 from "../components/Header2";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  resetOpponentScore,
  setOpponentImage,
  setOpponentName,
} from "../slices/opponentSlice";
import { useNavigate } from "react-router-dom";
import { resetScore } from "../slices/userSlice";
import { ResetIndex } from "../slices/indexSlice";
import { ResetTime, ResetTimeForProgress } from "../slices/timeSlice";
import { resetQuestions } from "../slices/questionsSlice";
import { resetCorrectAnswer, resetWrongAnswer } from "../slices/answersSlice";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const sampleOpponents = [
  {
    image: "/images/opponent1-girl.png",
    name: "Emily",
  },
  {
    image: "/images/opponent5-girl.jpg",
    name: "Olivia",
  },
  {
    image: "/images/opponent3-boy.jpg",
    name: "Jackson",
  },
  {
    image: "/images/opponent4-boy.jpg",
    name: "Michael",
  },
];

const FindingOpponent = () => {
  const { category, categoryId, mode, modePoint } = useParams();
  // console.log(point);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [opponentState, setOpponentState] = useState(sampleOpponents);
  const [index, setIndex] = useState(null);
  const [show, setShow] = useState(false);

  const { opponent } = useSelector((state) => state.opponent);
  const { points } = useSelector((state) => state.user.user);
  console.log(points);

  useEffect(() => {
    setTimeout(() => {
      setOpponentState(sampleOpponents);
      setIndex(Math.floor(Math.random() * (opponentState.length - 0)) + 0);
      console.log({ index });
      dispatch(setOpponentName(opponentState[index]?.name));
      dispatch(setOpponentImage(opponentState[index]?.image));
      setShow(true);
    }, 500);
  }, []);

  dispatch(setOpponentName(opponentState[index]?.name));
  dispatch(setOpponentImage(opponentState[index]?.image));

  const handleNavigate = (route) => {
    if (points < modePoint) {
      toast.error("You do not have enough points to play!");
      return;
    }
    navigate(route);
    dispatch(resetScore());
    dispatch(ResetIndex());
    dispatch(ResetTime());
    dispatch(ResetTimeForProgress());
    dispatch(resetOpponentScore());
    dispatch(resetQuestions());
    dispatch(resetCorrectAnswer());
    dispatch(resetWrongAnswer());
  };

  return (
    <Layout>
      <Header2 title="Finding Opponent" />
      <Box my={2} sx={{ backgroundColor: "#e9f1fa", borderRadius: "20px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1rem 2rem",
          }}
        >
          <Box sx={{ borderRadius: "50%", width: "20%" }}>
            <img
              style={{
                borderRadius: "50%",
                width: "100%",
                height: "100%",
                objectFit: "contain",
                objectPosition: "center center",
              }}
              src="/images/Avatar.jpg"
              // src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIMAAACDCAMAAACZQ1hUAAAAY1BMVEUiLTqzusC2vcO5wMa8w8keKjccKDYYJDMRIC8VIjEZJjQgKzkNHS2wt72Ij5YAEiWRmJ8HGSpfZ3Ckq7J2fYWBiJCepaxJUVsxO0Y3QEuqsbdCS1ZYYGltdX08RVAqNEFQWWLPmQz1AAAEEklEQVR4nO2a23KjMAyGAWNjczDmDAFC3v8pFxLaJt2sYxGLdmb5r9orvpFkSZHkOIcOHTp06NChQ4f+S9GAcyEE5wH9ke8zEcfnvKyklFWZn7NY7M0hsr6sie8Tb9HyR12es2BPgiiv50+795r/r3O2FwVlw4k8AqwYpBj2iQze1v4TgJVCTQIfIc69Zzb4pHCHGJmABiXRECwiI7IlYvkKYYYoM0yE1ABhhqhSPAQx/jMaH+TnaO4IGl003svrQyyIwpihRmLIKpNguIlUKHHJOmOCRS1GwhTS1BNXb1QIYUknc09cIRz7huAvE+SjyMitMzg1xBXL02C2CegZRDArOdt2hmmK/JKf225oMtCrWESk7SKeFkAE1y1sV64oATOcLPsiOsMZks5uULIejOC6/S9gaOxmCNZsYbBrB/oL7EA7eEy6thMlO4ERkimyy7AhR51st1KZguZqT9nOk9D2YWkpbdcLdgHXrIvt3pq20KA82e9qQ2BAIPRRjshhTQxKPxkB7eBYzg6LOOz3hcT42ct6EIPlYrEqBUSl/QR1E4XUbxwzmA2CbrLfU38omkwLeDKhjSnDi+Es6II4rzUbgxCJOpgLa4PZIEKWvheNXkKQGmHy8AjxajDmFROuGa4QWkuQHRBmiFD++3X4ku2zzokvyXNTkAR9aP8p0Ur376jwXNXusLz4EI0bRR72GJ5HVMN3CIV7irQta/dzp+bW5ZTuv2CkIpiasZJKVmMzhQKhbTLCYPwqwX4I4NChX6coYmEYLApDFu2eHJjgoTN1zWXIFw2XppuccH6j+3yesjjNzmOliuR2dEBuJwhJoeZcxdMYu3BSkU1DVRCfPFl6X5EKObQp4jlGEHajSr4fPnwH8ZI67wKUxpoKNqjEN/m55/mJykPrxqBZW5oBfGJUndVCyuJeaT3w3Cuqj609FN7JBEpwpUhkZ2cYE2Yj2AYfIm7JLURnNpygk8kHiiTP3gwLFsnNRrhpDgvnrUGlOBfvGGE1RdG/ERXp9kh4NMW4dTJEY8DBg15ExpuCgjrGox8TCGdLqhDKHsIMoeBXbFFmMO0AQdRgdwiLjlghFDBb8RK65jeAqEBPNM5tW+EKkQMmAwx8cGEmrzd/HNz4GAvIUBh7AyMYbiKlIQRsSQCEaMzqFwdeAEHk1UbTonDAQ5ghjC51UqSAXBlOBiU0REkNXyIGhggQo2GRwWkl67He5Yf8l2sm833RVnmv9kzwtTZcrxbhuA/zJm/QR2WG7orFGfpVE8d3hesm2qKx6RQLLm0JD3L8cJidMepSBK92YZA6Z2TISXJlKHRBmW64BtsiHUO8hxlmQ2hWDdTBzw6LfM11J22xC9bKoLnmo91ODJoEgV+4VwbNCdnB8KMMfwBRxT2y5SYoeAAAAABJRU5ErkJggg=="

              // src="https://demo.quizpro.mobi//uploads/user/2/avatar.png"
              alt="You"
            />
            <Typography sx={{ textAlign: "center" }} variant="h6">
              You
            </Typography>
          </Box>
          <Box
            mb={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">VS</Typography>
          </Box>
          {show ? (
            <Box sx={{ borderRadius: "50%", width: "20%" }}>
              <img
                loading="lazy"
                style={{
                  borderRadius: "50%",
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  objectPosition: "center center",
                }}
                // src={opponentState[index]?.image}
                src={opponent?.image}
                alt="random"
              />
              <Typography sx={{ textAlign: "center" }} variant="h6">
                {/* {opponentState[index]?.name} */}
                {opponent?.name}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Box sx={{ display: "block" }}>
                <CircularProgress
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </Box>
              <Box sx={{ position: "relative" }}>
                <Typography
                  sx={{
                    position: "absolute",
                    top: "10%",
                    right: "10%",
                    textAlign: "center",
                  }}
                  variant="h6"
                >
                  Finding ..
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box mt={2}>
          <Typography sx={{ textAlign: "center" }} variant="h6">
            {show ? "Challenge Accepted!" : "Finding Opponent..."}
          </Typography>
        </Box>
        <Box my={2}>
          <Button
            variant="contained"
            disabled={!show}
            // will be dynamic route
            onClick={() =>
              handleNavigate(
                `${
                  localStorage.getItem("serviceId") == 8
                    ? `/game/${category}/${categoryId}/play/${mode}/${modePoint}?serviceId=${localStorage.getItem(
                        "serviceId"
                      )}`
                    : `/game/${category}/${categoryId}/play/${mode}/${modePoint}`
                }`
              )
            }
            sx={{
              backgroundColor: "#FE9E44",
              fontWeight: "bold",
              color: "#fff",
              width: "90%",
              margin: "0 5%",
            }}
          >
            Play
          </Button>
        </Box>
      </Box>
    </Layout>
  );
};

export default FindingOpponent;
