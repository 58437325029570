import { configureStore } from "@reduxjs/toolkit";
import SearchSlice from "./slices/searchSlice";
import LoginModalSlice from "./slices/loginModalSlice";
import DifficultyModalSlice from "./slices/difficultyLevelsSlice";
import opponentSlice from "./slices/opponentSlice";
import UserSlice from "./slices/userSlice";
import IndexSlice from "./slices/indexSlice";
import TimeSlice from "./slices/timeSlice";
import QuestionsSlice from "./slices/questionsSlice";
import AnswersSlice from "./slices/answersSlice";
// import answerCheckSlice from "./slices/answerCheckSlice";

const store = configureStore({
  reducer: {
    search: SearchSlice.reducer,
    loginModal:LoginModalSlice.reducer,
    difficultyModal:DifficultyModalSlice.reducer,
    opponent:opponentSlice.reducer,
    user:UserSlice.reducer,
    index:IndexSlice.reducer,
    time:TimeSlice.reducer,
    questions:QuestionsSlice.reducer,
    answers:AnswersSlice.reducer,
    // answerCheck:answerCheckSlice.reducer
  },
});
export default store;
