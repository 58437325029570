import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { removeUserCredentials } from "../slices/userSlice";
import { javaCheckUserApi, zainOperatorCheckUserApi, zainOperatorLoginApi } from "../api";

const Auth = ({ children }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const checkUserBilling = async () => {
    try {
      const data = { mobileNumber: user?.username };

      if (localStorage.getItem("serviceId") == 8) {
        const res = await axios.post(zainOperatorCheckUserApi, data);

        if (res?.data?.response !== "Success") {
          if (user?.username) {
            dispatch(removeUserCredentials());
            toast.error("Bill Pending!");
            if (localStorage.getItem("serviceId") == 8) {
              navigate("/login?serviceId=8");
            } else {
              navigate("/login");
            }
          } else {
            dispatch(removeUserCredentials());
            if (localStorage.getItem("serviceId") == 8) {
              navigate("/login?serviceId=8");
            } else {
              navigate("/login");
            }
          }
        }
      } else {
        const res = await axios.post(javaCheckUserApi, data);

        // const data = { mobileNumber: user?.username };
        // const res = await axios.post(
        //   "https://www.backend.quizboxdemo.visiontrek.in/api/checkUser",
        //   data
        // );
        if (res?.data?.response !== "Success") {
          if (user?.username) {
            dispatch(removeUserCredentials());
            toast.error("Bill Pending!");
            if (localStorage.getItem("serviceId") == 8) {
              navigate("/login?serviceId=8");
            } else {
              navigate("/login");
            }
          } else {
            dispatch(removeUserCredentials());
            if (localStorage.getItem("serviceId") == 8) {
              navigate("/login?serviceId=8");
            } else {
              navigate("/login");
            }
          }
        }
      }
    } catch (error) {
      toast.error(error?.data?.message || error?.message);
    }
  };
  useEffect(() => {
    if (user?.username) {
      checkUserBilling();
    } else {
      if (localStorage.getItem("serviceId") == 8) {
        navigate("/login?serviceId=8");
      } else {
        navigate("/login");
      }
    }
  }, []);
  return <div>{children}</div>;
};

export default Auth;
