import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import Header from "../components/Header";
import { Box, Typography } from "@mui/material";
import BottomNavbar from "../components/BottomNavbar";
import SearchBox from "../components/SearchBox";
import axios from "axios";
import { baseURL, quizURL } from "../api";
import PopularQuiz2 from "../components/PopularQuiz2";

const NoQuestionsFound = () => {
  const [quizes, setQuizes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDataFromBackend = async () => {
      try {
        setLoading(true);
        const res = await axios.post(`${baseURL}${quizURL}`);
        console.log(res);
        setQuizes(res.data.result);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    fetchDataFromBackend();
  }, []);
  return (
    <Layout>
      <Header />
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Typography variant="h3">
          No Questions Found! Try again or play another game...
        </Typography>
      </Box>
      <Box mb={4}>
        <PopularQuiz2 quizes={quizes} loading={loading} />
      </Box>

      <SearchBox />
      <BottomNavbar />
    </Layout>
  );
};

export default NoQuestionsFound;
