import React, { useState, useEffect } from "react";
import BottomNavbar from "../components/BottomNavbar";
import Header from "../components/Header";
import Layout from "../components/Layout";
import Heading from "../components/Heading";
import SearchBox from "../components/SearchBox";
import GridViewIcon from "@mui/icons-material/GridView";

import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Avatar,
  Divider,
  Typography,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import classes from "./AllQuizes.module.css";
import axios from "axios";
import { baseURL, quizURL } from "../api";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const AllQuizes = () => {
  const [quizes, setQuizes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDataFromBackend = async () => {
      try {
        setLoading(true);
        const res = await axios.post(`${baseURL}${quizURL}`);
        // console.log(res);
        setQuizes(res.data.result);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error(err?.response?.data?.message || err?.message);
        console.log(err);
      }
    };
    fetchDataFromBackend();
  }, []);

  console.log(quizes);
  return (
    <Layout>
      <Header />
      <SearchBox />
      <Heading
        title="All Quizes"
        icon={<GridViewIcon sx={{ position: "relative", top: "11%" }} />}
      />
      <Box
        mb={4}
        mt={-2}
        sx={{ backgroundColor: "#e9f1fa", borderRadius: "20px" }}
      >
        <Box sx={{ margin: "0 2rem", padding: "2rem 0" }}>
          <List>
            {loading ? (
              <Loader />
            ) : (
              <>
                {quizes.map((dataItem) => {
                  return (
                    <React.Fragment key={dataItem.id}>
                      <ListItem
                        // key={dataItem.id}
                        secondaryAction={
                          <Link
                            to={`
                          ${
                            localStorage.getItem("serviceId") == 8
                              ? `
                            /game/details/${dataItem.categoryName}/${
                                  dataItem.categoryId
                                }?serviceId=${localStorage.getItem("serviceId")}
                            `
                              : `/game/details/${dataItem.categoryName}/${dataItem.categoryId}`
                          }
                          `}
                          >
                            <IconButton edge="end" aria-label="link">
                              <ArrowForwardIosIcon />
                            </IconButton>
                          </Link>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar
                            src={dataItem.categoryImage}
                            variant="square"
                            alt={dataItem.categoryName}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold" }}
                              className={classes.game_title}
                            >
                              {dataItem.categoryName}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              variant="body2"
                              className={classes.game_description}
                            >
                              {dataItem.description}
                            </Typography>
                          }
                        />
                      </ListItem>

                      <Divider />
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </List>
        </Box>
      </Box>
      <BottomNavbar value={1} />
    </Layout>
  );
};

export default AllQuizes;
