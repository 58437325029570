import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Divider } from "@mui/material";

export default function AccordianComponent({
  correctAnswer,
  wrongAnswer,
  correctQuestions,
  wrongQuestions,
  answerChoosed
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            Correct Answers
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            {correctAnswer}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {correctQuestions.length > 0 &&
              correctQuestions.map((questions, i) => {
                return (
                  <Box key={i} sx={{display:"flex",flexDirection:'column'}}>
                    <Box sx={{display:"flex",flexDirection:"row",gap:"1rem"}}>
                        <Typography>
                            {i+1}). 
                        </Typography>
                        <Typography>
                            {questions?.question}
                        </Typography>
                    </Box>
                    <Typography variant="body1" sx={{marginLeft:"2rem"}}>
                        Correct Answer: {questions?.correctAnswer}
                    </Typography>
                    <Divider />
                  </Box>
                );
              })}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            Wrong Answers
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            {wrongAnswer}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box>
            {wrongQuestions.length > 0 &&
              wrongQuestions.map((questions, i) => {
                return (
                  <Box key={i} sx={{display:"flex",flexDirection:'column'}}>
                    <Box sx={{display:"flex",flexDirection:"row",gap:"1rem"}}>
                        <Typography>
                            {i+1}). 
                        </Typography>
                        <Typography>
                            {questions?.question}
                        </Typography>
                    </Box>
                    <Typography variant="body1" sx={{marginLeft:"2rem"}}>
                        Correct Answer: {questions?.correctAnswer}
                    </Typography>
                    <Typography variant="body1" sx={{marginLeft:"2rem"}}>
                        Your Answer: {answerChoosed[i]}
                    </Typography>
                    <Divider />
                  </Box>
                );
              })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
