import React, { useEffect } from "react";
import Layout from "../components/Layout";
import Header from "../components/Header";
import {
  Avatar,
  List,
  IconButton,
  Box,
  Button,
  Divider,
  ListItem,
  ListItemText,
  Typography,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SearchBox from "../components/SearchBox";
import BottomNavbar from "../components/BottomNavbar";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeUserCredentials } from "../slices/userSlice";

const Account = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const token = user?.response;
  useEffect(() => {
    if (!token) {
      if (localStorage.getItem("serviceId") == 8) {
        navigate("/login?serviceId=8");
      } else {
        navigate("/login");
      }
    }
  }, [token, navigate]);

  const logoutHandler = () => {
    dispatch(removeUserCredentials());
  };
  return (
    <Layout>
      <Header />
      <Box
        sx={{
          backgroundColor: "#e9f1fa",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            margin: "2rem 2rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDireaction: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDireaction: "row", gap: "1rem" }}>
              <Avatar />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">{user?.username}</Typography>
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  backgroundColor: "#47c475",
                  color: "#fff",
                  borderRadius: "12px",
                  padding: "0.2rem 1rem",
                }}
              >
                Subscriber
              </Box>
            </Box>
          </Box>
          <Button
            variant="contained"
            sx={{
              //   margin: "0 2rem",
              backgroundColor: "#969696",
              color: "#fff",
              fontWeight: "bold",
            }}
          >
            Unsubscribe
          </Button>
        </Box>
      </Box>

      <Box
        mb={4}
        mt={-2}
        sx={{ backgroundColor: "#e9f1fa", borderRadius: "20px" }}
      >
        <Box sx={{ margin: "0 2rem", padding: "2rem 0" }}>
          <List>
            <ListItem
              disableGutters
              secondaryAction={
                <IconButton
                  onClick={() =>
                    navigate(`
                    {${
                      localStorage.getItem("serviceId") == 8
                        ? `/history?serviceId=${localStorage.getItem(
                            "serviceId"
                          )}`
                        : `/history`
                    }}
                    `)
                  }
                  edge="end"
                  aria-label="link"
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              }
            >
              <ListItemButton
                onClick={() =>
                  navigate(`
                    {${
                      localStorage.getItem("serviceId") == 8
                        ? `/history?serviceId=${localStorage.getItem(
                            "serviceId"
                          )}`
                        : `/history`
                    }}
                    `)
                }
              >
                <ListItemIcon>
                  <SportsEsportsIcon
                    sx={{ color: "#0A58CA", fontSize: "1.5rem" }}
                  />
                </ListItemIcon>
                <ListItemText primary="History" />
              </ListItemButton>
            </ListItem>

            <Divider />

            <ListItem
              disableGutters
              secondaryAction={
                <IconButton
                  onClick={logoutHandler}
                  edge="end"
                  aria-label="link"
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              }
            >
              <ListItemButton onClick={logoutHandler}>
                <ListItemIcon>
                  <ExitToAppIcon
                    sx={{ color: "#0A58CA", fontSize: "1.5rem" }}
                  />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Box>
      <SearchBox />
      <BottomNavbar value={3} label="Account" />
    </Layout>
  );
};

export default Account;
