// const baseURL="http://5.189.166.187:5252/";
// const baseURL="http://5.189.166.187:4041/";
// const baseURL="http://192.168.1.8:4041/";
// const baseURL="http://192.168.1.11:4041/";
// const baseURL="http://192.168.1.37:4141/";
const baseURL="/";
// const baseURL="https://quizbox.gamewins.co.za/";

export {baseURL};

const loginURL="user/login";
export {loginURL};

const registerURL="user/register";
export {registerURL};

const questionsURL="api/v1/getQuestions";
export {questionsURL};

const quizURL="user/getCategory";
export {quizURL};

const getGameDescription="user/getDescription";
export {getGameDescription};

const levelsURL="user/level";
export {levelsURL};

const saveScore="api/v1/saveScore";
export {saveScore};

const deductPoints="api/v1/deductPoint";
export {deductPoints};

const historyURL="api/v1/history";
export {historyURL};

const leaderBoardURL="user/topLeadingPlayers";
export {leaderBoardURL};

const gameLinkURL="api/v1/generateLink";
export {gameLinkURL};

const validateLinkOfGame="user/validateLink";
export {validateLinkOfGame};

const search="user/searchData";
export {search};

// const javaLoginApi="https://backend.kiddocrazedemo.visiontrek.in/api/login";
// export {javaLoginApi};

// const javaCheckUserApi="https://backend.kiddocrazedemo.visiontrek.in/api/checkUser";
// export {javaCheckUserApi};

const javaLoginApi="https://www.backend.quizboxdemo.visiontrek.in/login";
export {javaLoginApi};

const javaCheckUserApi="https://www.backend.quizboxdemo.visiontrek.in/checkUser";
export {javaCheckUserApi};



const zainOperatorLoginApi="https://www.zaincallback.visiontrek.in/zain/api/login";
export {zainOperatorLoginApi};

const zainOperatorCheckUserApi="https://www.zaincallback.visiontrek.in/zain/api/checkUser";
export {zainOperatorCheckUserApi};