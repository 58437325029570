import React, { useEffect,useState } from 'react'
import Layout from '../components/Layout'
import Header2 from '../components/Header2'
import axios from 'axios'
import {toast} from 'react-toastify';
import { useSelector } from 'react-redux';
import { Box,List, Typography,ListItem,ListItemAvatar,Divider,ListItemText,Avatar } from '@mui/material';
import Loader from '../components/Loader';
import { baseURL,historyURL } from '../api';
import classes from "./AllQuizes.module.css";

const History = () => {
    const {user}=useSelector(state=>state.user);
    const [histories,setHistories]=useState([]);
    const [loading,setLoading]=useState();
    useEffect(()=>{
        const fetchDataFromBackend=async()=>{
            try{
                setLoading(true);
                const res=await axios.get(`${baseURL}${historyURL}`,{
                    headers:{
                        "Authorization":`Bearer ${user?.response}`
                    }
                });
                // console.log(res);
                setHistories(res.data.result);
                setLoading(false);
            }
            catch(err){
                setLoading(false);
                toast.error(err?.data?.message || err?.response || err?.message ||err?.response?.data?.message);
            }
            
        }
        fetchDataFromBackend();
    },[])
  return (
    <Layout>
        <Header2 title="History"/>

        <Box
        mb={4}
        mt={-2}
        sx={{ backgroundColor: "#e9f1fa", borderRadius: "20px" }}
      >
        <Box sx={{ margin: "0 2rem", padding: "2rem 0" }}>
          <List>
            {loading ? (
              <Loader />
            ) : (
              <>
                 {histories.map((history,i) => {
                  return (
                    <>
                      <ListItem
                      key={i}
                        secondaryAction={
                          
                          <Typography variant="body2">{history.dateTime.substring(0,10)}</Typography>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar
                            src={history.categoryImage}
                            variant="square"
                            alt={history.categoryName}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold" }}
                              className={classes.game_title}
                            >
                              {history.categoryName}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              variant="body2"
                              className={classes.game_description}
                            >
                              Score: {history.score}
                            </Typography>
                          }
                        />
                      </ListItem>

                      <Divider />
                    </>
                  );
                })}
              </>
            )} 
          </List>
        </Box>
      </Box>
    </Layout>
  )
}

export default History