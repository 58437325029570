import React, { useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./routes/Home";
import AllQuizes from "./routes/AllQuizes";
import LeaderBoard from "./routes/Leaderboard";
import Login from "./routes/Login";
import Signup from "./routes/Signup";
import GameDetails from "./routes/GameDetails";
import FindingOpponent from "./routes/FindingOpponent";
import GamePlayDashboard from "./routes/GamePlayDashboard";
import GameResults from "./routes/GameResults";
import Account from "./routes/Account";
import NoQuestionsFound from "./routes/NoQuestionsFound";
import GameLink from "./routes/GameLink";
import History from "./routes/History";
import PlayWithFriendDashBoard from "./routes/PlayWithFriendDashBoard";
import Auth from "./components/Auth";
import ErrorBoundary from "./components/ErrorBoundary";
import PageNotFound from "./routes/PageNotFound";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/all-quizes",
      element: <AllQuizes />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/leaderboard",
      element: <LeaderBoard />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/login",
      element: <Login />,
      errorElement: <ErrorBoundary />,
    },
    // {
    //   path: "/signup",
    //   element: <Signup />,
    // },
    {
      path: "/account",
      element: (
        <Auth>
          <Account />
        </Auth>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/game/details/:category/:categoryId",
      element: <GameDetails />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/game/:category/:categoryId/search/opponent/:mode/:modePoint",
      element: (
        <Auth>
          <FindingOpponent />
        </Auth>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/game/:category/:categoryId/play/:mode/:modePoint",
      element: (
        <Auth>
          <GamePlayDashboard />
        </Auth>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/game/:category/:categoryId/:mode/results",
      element: <GameResults />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/oops",
      element: <NoQuestionsFound />,
      errorElement: <ErrorBoundary />,
    },
    // {
    //   path: "/game/link",
    //   element: <GameLink />,
    // },
    {
      path: "/history",
      element: (
        <Auth>
          <History />
        </Auth>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: "*",
      element: <PageNotFound />,
      errorElement: <ErrorBoundary />,
    },
    // {
    //   path: "/:linkPlayWithFriend",
    //   element: <PlayWithFriendDashBoard />,
    // },
  ]);
  return (
    <>
      <RouterProvider router={router} />;
    </>
  );
};

export default App;
