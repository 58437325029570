import React from "react";
import {
  Box,
  Paper,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import { showDifficultyModalBox } from "../slices/difficultyLevelsSlice";
import { useDispatch } from "react-redux";

const BottomNavbar2 = ({ category, categoryId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleNavigate = (route) => {
    dispatch(showDifficultyModalBox());
    if (localStorage.getItem("serviceId") == 8) {
      navigate(`${route}?serviceId=8`);
    } else {
      navigate(route);
    }
  };

  const handleBackNavigation = () => {
    if (localStorage.getItem("serviceId") == 8) {
      navigate(`/?serviceId=8`);
    } else {
      navigate("/");
    }
  };
  return (
    <Box sx={{ backgroundColor: "#e9f1fa", borderRadius: "20px" }}>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: {
            xs: "5%",
            sm: "5%",
            md: "5%",
            lg: "32%",
            xl: "32%",
          },
          right: {
            xs: "5%",
            sm: "5%",
            md: "5%",
            lg: "32%",
            xl: "32%",
          },
          borderRadius: "24px 24px 0 0",
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={-1}
          sx={{
            backgroundColor: "#fff",
            borderRadius: "24px 24px 0 0",
            boxShadow: "0 -5px 5px rgba(12,9,42,0.1)",
          }}
        >
          <BottomNavigationAction
            onClick={() => handleBackNavigation()}
            label="Home"
            icon={<HomeIcon />}
          />
          <BottomNavigationAction
            onClick={() =>
              handleNavigate(`/game/details/${category}/${categoryId}`)
            }
            label="Play Again"
            icon={<SportsEsportsIcon />}
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

export default BottomNavbar2;
