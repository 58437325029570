import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { useSelector, useDispatch } from "react-redux";
import {
  Avatar,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  beginnerModeScoreDecrease,
  beginnerModeScoreIncrease,
  setUserPoints,
} from "../slices/userSlice";
import { IncreaseIndex } from "../slices/indexSlice";
import {
  DecreaseTime,
  IncreaseTimeForProgress,
  ResetTime,
  ResetTimeForProgress,
  SetProgress,
} from "../slices/timeSlice";

import { useNavigate } from "react-router-dom";
import { beginnerModeOpponentScore } from "../slices/opponentSlice";
import axios from "axios";
import { baseURL, deductPoints, questionsURL, saveScore } from "../api";
import { toast } from "react-toastify";
import { storeQuestions } from "../slices/questionsSlice";
import Loader from "../components/Loader";
import {
  increaseCorrectAnswer,
  increaseWrongAnswer,
} from "../slices/answersSlice";
import { useParams } from "react-router-dom";
import Audio from "../components/Audio";
import WrongAudio from "../components/WrongAudio";
import CorrectAudio from "../components/CorrectAudio";

const GamePlayDashboard = () => {
  const { category, categoryId, mode, modePoint } = useParams();
  const question = useSelector((state) => state.questions);
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const { opponent, opponentScore } = useSelector((state) => state.opponent);

  const { user } = useSelector((state) => state.user);
  const { questionPoints } = useSelector((state) => state.user);
  // console.log(questionPoints);
  const { index } = useSelector((state) => state.index);
  const { timeClock, progress } = useSelector((state) => state.time);
  const [time, setTime] = useState(timeClock);

  const [correct, setCorrect] = useState(null);
  const [wrong, setWrong] = useState(null);

  const [wrongSound, setWrongSound] = useState(null);
  const [correctSound, setCorrectSound] = useState(null);
  const [pointerEvents, setPointerEvents] = useState("all");

  const [loading, setLoading] = useState(false);
  const { score } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    //if check if no questions in store then fetch questions and store it in store
    // fetchQuestionsFromBackend();
    if (!loading) {
      const intervalId = setInterval(() => {
        setTime(time - 1);
        dispatch(DecreaseTime());
        dispatch(IncreaseTimeForProgress());
        dispatch(SetProgress());
      }, 1000);

      // Clean up the interval to avoid unexpected behavior
      return () => clearInterval(intervalId);
    }
  }, [time, loading]);

  async function fetchDataFromLocalStorage() {
    // console.log("fetch data from backend");

    try {
      setLoading(true);

      const res = await axios.get(
        `${baseURL}${questionsURL}?category=${category}&limit=10&type=${mode}`,
        {
          headers: {
            Authorization: `Bearer ${user.response}`,
          },
        }
      );
      // console.log(res);
      dispatch(storeQuestions(res.data.result));
      if (res.data.result <= 0) {
        if (localStorage.getItem("serviceId") == 8) {
          navigate(`/oops?serviceId=${localStorage.getItem("serviceId")}`);
        } else {
          navigate("/oops");
        }
      }
      //api cut user points
      try {
        const pointsRes = await axios.get(
          `${baseURL}${deductPoints}?points=${modePoint}`,
          {
            headers: {
              Authorization: `Bearer ${user.response}`,
            },
          }
        );
        setQuestions(res.data.result);
        dispatch(setUserPoints(pointsRes.data.result));
        setLoading(false);
      } catch (err) {
        console.log(err);
      }

      // console.log(res);
    } catch (err) {
      toast.error(err || err?.data?.message || err?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (
      !question.questions ||
      question.questions == "" ||
      question.questions.length < 0
    ) {
      fetchDataFromLocalStorage();
    } else {
      setQuestions(question?.questions);
    }
  }, []);

  if (index === questions?.length && questions.length > 0 && !loading) {
    // api send data to backend
    const sendScoreToBackend = async () => {
      try {
        setLoading(true);
        const res = await axios.get(
          `${baseURL}${saveScore}?score=${score}&category=${category}`,
          {
            headers: {
              Authorization: `Bearer ${user.response}`,
            },
          }
        );
        console.log(res);
        dispatch(setUserPoints(res.data.result));
        setLoading(false);
        if (localStorage.getItem("serviceId") == 8) {
          navigate(
            `/game/${category}/${categoryId}/${mode}/results?serviceId=${localStorage.getItem(
              "serviceId"
            )}`
          );
        } else {
          navigate(`/game/${category}/${categoryId}/${mode}/results`);
        }
      } catch (error) {
        console.log(error);
      }
    };
    sendScoreToBackend();
  }

  if (timeClock < 0 && !loading) {
    // console.log("api send");
    // api send data to backend of results..
    const sendScoreToBackend = async () => {
      try {
        setLoading(true);
        const res = await axios.get(
          `${baseURL}${saveScore}?score=${score}&category=${category}`,
          {
            headers: {
              Authorization: `Bearer ${user.response}`,
            },
          }
        );
        // console.log(res);
        dispatch(setUserPoints(res.data.result));
        if (localStorage.getItem("serviceId") == 8) {
          navigate(
            `/game/${category}/${categoryId}/${mode}/results?serviceId=8`
          );
        } else {
          navigate(`/game/${category}/${categoryId}/${mode}/results`);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    sendScoreToBackend();
  }

  const checkAnswerAndNextQuestion = (option) => {
    console.log(option);
    if (
      questions[index].correct == option.index ||
      questions[index].correct == option.option ||
      questions[index].correctAnswer == option.name
    ) {
      // console.log("correct");
      setCorrect(option.index);
      setCorrectSound(true);
      setWrong(null);
      setWrongSound(false);

      //We will increase the score
      dispatch(beginnerModeScoreIncrease(questionPoints));
      dispatch(beginnerModeOpponentScore(questionPoints));
      dispatch(increaseCorrectAnswer(questions[index]));
      setPointerEvents("none");
      setTimeout(() => {
        setCorrect(null);
        setWrong(null);
        setCorrectSound(false);
        setWrongSound(false);
        dispatch(IncreaseIndex());
        setPointerEvents("all");
      }, 650);

      // setCorrect(false);
    } else {
      // console.log("wrong");
      setWrong(option.index);
      setWrongSound(true);
      setCorrect(null);
      setCorrectSound(false);
      // dispatch(beginnerModeScoreDecrease(questionPoints));
      dispatch(beginnerModeOpponentScore(questionPoints));
      setPointerEvents("none");
      dispatch(
        increaseWrongAnswer({
          question: questions[index],
          yourAnswer: option.name,
        })
      );
      setTimeout(() => {
        setCorrect(null);
        setWrong(null);
        setCorrectSound(false);
        setWrongSound(false);
        dispatch(IncreaseIndex());
        setPointerEvents("all");
      }, 650);

      // setWrong(false);
    }
  };

  useEffect(() => {
    const handlePopState = () => {
      // Navigate to the desired endpoint
      if (localStorage.getItem("serviceId") == 8) {
        window.location.pathname = "/?serviceId=8";
      } else {
        window.location.pathname = "/";
      }
    };

    // Push initial state to prevent back button navigation
    window.history.pushState(null, null, document.URL);

    // Add event listener for popstate
    window.addEventListener("popstate", handlePopState);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <Layout>
      {loading ? (
        <Box
          sx={{
            width: "100%",
            minHeight: "100dvh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <>
          <Audio />
          {wrongSound && <WrongAudio />}
          {correctSound && <CorrectAudio />}
          <Box
            mt={4}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Avatar
                src="/images/Avatar.jpg"
                // src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIMAAACDCAMAAACZQ1hUAAAAY1BMVEUiLTqzusC2vcO5wMa8w8keKjccKDYYJDMRIC8VIjEZJjQgKzkNHS2wt72Ij5YAEiWRmJ8HGSpfZ3Ckq7J2fYWBiJCepaxJUVsxO0Y3QEuqsbdCS1ZYYGltdX08RVAqNEFQWWLPmQz1AAAEEklEQVR4nO2a23KjMAyGAWNjczDmDAFC3v8pFxLaJt2sYxGLdmb5r9orvpFkSZHkOIcOHTp06NChQ4f+S9GAcyEE5wH9ke8zEcfnvKyklFWZn7NY7M0hsr6sie8Tb9HyR12es2BPgiiv50+795r/r3O2FwVlw4k8AqwYpBj2iQze1v4TgJVCTQIfIc69Zzb4pHCHGJmABiXRECwiI7IlYvkKYYYoM0yE1ABhhqhSPAQx/jMaH+TnaO4IGl003svrQyyIwpihRmLIKpNguIlUKHHJOmOCRS1GwhTS1BNXb1QIYUknc09cIRz7huAvE+SjyMitMzg1xBXL02C2CegZRDArOdt2hmmK/JKf225oMtCrWESk7SKeFkAE1y1sV64oATOcLPsiOsMZks5uULIejOC6/S9gaOxmCNZsYbBrB/oL7EA7eEy6thMlO4ERkimyy7AhR51st1KZguZqT9nOk9D2YWkpbdcLdgHXrIvt3pq20KA82e9qQ2BAIPRRjshhTQxKPxkB7eBYzg6LOOz3hcT42ct6EIPlYrEqBUSl/QR1E4XUbxwzmA2CbrLfU38omkwLeDKhjSnDi+Es6II4rzUbgxCJOpgLa4PZIEKWvheNXkKQGmHy8AjxajDmFROuGa4QWkuQHRBmiFD++3X4ku2zzokvyXNTkAR9aP8p0Ur376jwXNXusLz4EI0bRR72GJ5HVMN3CIV7irQta/dzp+bW5ZTuv2CkIpiasZJKVmMzhQKhbTLCYPwqwX4I4NChX6coYmEYLApDFu2eHJjgoTN1zWXIFw2XppuccH6j+3yesjjNzmOliuR2dEBuJwhJoeZcxdMYu3BSkU1DVRCfPFl6X5EKObQp4jlGEHajSr4fPnwH8ZI67wKUxpoKNqjEN/m55/mJykPrxqBZW5oBfGJUndVCyuJeaT3w3Cuqj609FN7JBEpwpUhkZ2cYE2Yj2AYfIm7JLURnNpygk8kHiiTP3gwLFsnNRrhpDgvnrUGlOBfvGGE1RdG/ERXp9kh4NMW4dTJEY8DBg15ExpuCgjrGox8TCGdLqhDKHsIMoeBXbFFmMO0AQdRgdwiLjlghFDBb8RK65jeAqEBPNM5tW+EKkQMmAwx8cGEmrzd/HNz4GAvIUBh7AyMYbiKlIQRsSQCEaMzqFwdeAEHk1UbTonDAQ5ghjC51UqSAXBlOBiU0REkNXyIGhggQo2GRwWkl67He5Yf8l2sm833RVnmv9kzwtTZcrxbhuA/zJm/QR2WG7orFGfpVE8d3hesm2qKx6RQLLm0JD3L8cJidMepSBK92YZA6Z2TISXJlKHRBmW64BtsiHUO8hxlmQ2hWDdTBzw6LfM11J22xC9bKoLnmo91ODJoEgV+4VwbNCdnB8KMMfwBRxT2y5SYoeAAAAABJRU5ErkJggg=="
                // src="https://demo.quizpro.mobi//uploads/user/2/avatar.png"
                alt="user"
              />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  px={2}
                  sx={{ color: "white", fontWeight: "bold" }}
                  variant="body2"
                >
                  You
                </Typography>
                <Typography
                  px={2}
                  sx={{ color: "white", fontWeight: "bold" }}
                  variant="body2"
                >
                  {score}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{ color: "white", position: "absolute" }}
              >
                {timeClock}
              </Typography>
              <CircularProgress
                sx={{ color: "white" }}
                variant="determinate"
                value={progress}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  px={2}
                  sx={{ color: "white", fontWeight: "bold" }}
                  variant="body2"
                >
                  {opponent.name}
                </Typography>
                <Typography
                  px={2}
                  sx={{ color: "white", fontWeight: "bold" }}
                  variant="body2"
                >
                  {opponentScore}
                </Typography>
              </Box>
              <Avatar src={opponent.image} alt={opponent.name} />
            </Box>
          </Box>

          <Box my={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" sx={{ color: "white" }}>
                {index + 1} of {questions?.length}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#fd7e14",
              color: "white",
              //   gap: "2rem",
              flexDirection: "column",
              // backgroundColor: "#e9f1fa",
              borderRadius: "20px",
            }}
          >
            {questions[index]?.imgUrl && questions[index]?.imgUrl != null && (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  key={index}
                  style={{ width: "100%", height: "300px" }}
                  src={questions[index]?.imgUrl}
                  alt="random"
                  loading="lazy"
                />
              </Box>
            )}
            {questions[index]?.videoUrl && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <video
                  key={index}
                  controls
                  style={{ width: "100%", height: "300px" }}
                  autoPlay
                  loop
                >
                  <source
                    style={{
                      width: "100%",
                      height: "300px",
                      objectFit: "cover",
                    }}
                    src={questions[index]?.videoUrl}
                    type="video/mp4"
                  />
                </video>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                borderRadius: "1rem",
                backgroundColor: "#fd7e14",
                color: "white",
                justifyContent: "center",
                alignItems: "center",
                padding: "2rem 1rem",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", userSelect: "none" }}
              >
                {/* {questions.length>0 && !loading ? 
                  questions[index]?.question : "No Questions Found Sorry"
                } */}
                {questions[index]?.question}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ pointerEvents: pointerEvents, userSelect: "none" }}>
            <List
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              {questions[index]?.optionA && (
                <ListItem
                  onClick={() =>
                    checkAnswerAndNextQuestion({
                      option: "A",
                      index: "1",
                      name: questions[index]?.optionA,
                    })
                  }
                  sx={{
                    cursor: "pointer",
                    borderRadius: "2rem",
                    backgroundColor:
                      correct == "1" ? "green" : wrong == "1" ? "red" : "white",
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        1. {questions[index]?.optionA}
                      </Typography>
                    }
                  />
                </ListItem>
              )}

              {questions[index]?.optionB && (
                <ListItem
                  onClick={() =>
                    checkAnswerAndNextQuestion({
                      option: "B",
                      index: "2",
                      name: questions[index]?.optionB,
                    })
                  }
                  sx={{
                    cursor: "pointer",
                    borderRadius: "2rem",
                    backgroundColor:
                      correct == "2" ? "green" : wrong == "2" ? "red" : "white",
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        2. {questions[index]?.optionB}
                      </Typography>
                    }
                  />
                </ListItem>
              )}

              {questions[index]?.optionC && (
                <ListItem
                  onClick={() =>
                    checkAnswerAndNextQuestion({
                      option: "C",
                      index: "3",
                      name: questions[index]?.optionC,
                    })
                  }
                  sx={{
                    cursor: "pointer",
                    borderRadius: "2rem",
                    backgroundColor:
                      correct == "3" ? "green" : wrong == "3" ? "red" : "white",
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        3. {questions[index]?.optionC}
                      </Typography>
                    }
                  />
                </ListItem>
              )}

              {questions[index]?.optionD && (
                <ListItem
                  onClick={() =>
                    checkAnswerAndNextQuestion({
                      option: "D",
                      index: "4",
                      name: questions[index]?.optionD,
                    })
                  }
                  sx={{
                    cursor: "pointer",
                    borderRadius: "2rem",
                    backgroundColor:
                      correct == "4" ? "green" : wrong == "4" ? "red" : "white",
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        4. {questions[index]?.optionD}
                      </Typography>
                    }
                  />
                </ListItem>
              )}
            </List>
          </Box>
        </>
      )}
    </Layout>
  );
};

export default GamePlayDashboard;
